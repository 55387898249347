// Import node module libraries
import { Fragment } from "react";
import { Card, Row } from "react-bootstrap";

// Import custom components
import ApexCharts from "components/elements/charts/ApexCharts";

const AnSummary = ({
  ventaData,
  litrosData,
  utilidadData,
  priceData,
  costData,
  xAxisData,
  title,
}) => {
  const theme = {
    primary: "var(--geeks-primary)",
    danger: "var(--geeks-danger)",
    purple: "#6f42c1", // New purple color
    orange: "#f39c12", // New orange color
    info: "var(--geeks-info)",
    gray500: "var(--geeks-gray-500)",
    navy: "#1f77b4", // New navy color
    teal: "#2ca02c",
    success: "var(--geeks-success)",
    borderColor: "var(--geeks-border-color)",
    blue: "#007bff",
    green: "#28a745",
  };

  const SessionChartSeries = [
    {
      name: "Litros",
      data: litrosData,
      colors: [theme.orange],
    },
    {
      name: "Utilidad",
      data: utilidadData,
      colors: [theme.success],
    },
    {
      name: "Costo", // Ensure the name matches the data
      data: costData,
      colors: [theme.danger], // Color for Cost line
    },
    {
      name: "Precio", // Ensure the name matches the data
      data: priceData,
      colors: [theme.blue], // Color for Price line
    },
  ];

  const SessionChartOptions = {
    chart: {
      toolbar: { show: false },
      height: 200,
      type: "line",
      zoom: { enabled: false },
    },
    dataLabels: { enabled: false },
    stroke: { width: [3, 3, 3, 3], curve: "smooth", dashArray: [0, 0, 4, 4] },
    legend: { show: false },
    colors: [theme.orange, theme.success, theme.danger, theme.blue], // Ensure colors are in the right order
    markers: { size: 0, hover: { sizeOffset: 6 } },
    xaxis: {
      categories: xAxisData,
      labels: {
        style: {
          colors: [theme.gray500],
          fontSize: "12px",
          fontFamily: "Inter",
          cssClass: "apexcharts-xaxis-label",
        },
      },
      axisBorder: {
        show: true,
        color: theme.borderColor,
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: theme.borderColor,
        height: 6,
      },
    },
    yaxis: [
      {
        seriesName: "Litros",
        title: {
          text: "Litros",
          style: {
            color: theme.orange,
          },
        },
        labels: {
          style: {
            colors: [theme.orange],
          },
          formatter: function (value) {
            return Math.round(value); // Round to whole numbers
          },
        },
        min: Math.min(...litrosData) - 5000, // Set min value
        max: Math.max(...utilidadData) + 5000, // Set max value
      },
      {
        seriesName: "Utilidad",
        opposite: true,
        title: {
          text: "Utilidad",
          style: {
            color: theme.success,
          },
        },
        labels: {
          style: {
            colors: [theme.success],
          },
          formatter: function (value) {
            return Math.round(value); // Round to whole numbers
          },
        },
        min: Math.min(...litrosData) - 5000, // Set min value
        max: Math.max(...utilidadData) + 5000, // Set max value
      },
      {
        seriesName: "Costo",
        title: {
          text: "Costo",
          style: {
            color: theme.danger,
          },
        },
        labels: {
          style: {
            colors: [theme.danger],
          },
          formatter: function (value) {
            return value.toFixed(3); // Show 3 decimal places
          },
        },
        min: Math.min(...costData) - 0.5, // Set min value
        max: Math.max(...priceData) + 0.5, // Set max value
        // tickAmount: 10, // Increase the number of ticks for better visibility
        forceNiceScale: true, // Force nice scale
      },
      {
        seriesName: "Precio",
        opposite: true,
        title: {
          text: "Precio",
          style: {
            color: theme.blue,
          },
        },
        labels: {
          style: {
            colors: [theme.blue],
          },
          formatter: function (value) {
            return value.toFixed(3); // Show 3 decimal places
          },
        },
        min: Math.min(...costData) - 0.5, // Set min value
        max: Math.max(...priceData) + 0.5, // Set max value
        // tickAmount: 10, // Increase the number of ticks for better visibility
        forceNiceScale: true, // Force nice scale
      },
    ],
    tooltip: {
      y: {
        formatter: function (val) {
          return Math.round(val * 1000) / 1000; // Round to 3 decimal places in tooltip
        },
      },
    },
    grid: { borderColor: theme.borderColor },
    responsive: [
      { breakpoint: 480, options: { chart: { height: 300 } } },
      { breakpoint: 1441, options: { chart: { height: 360 } } },
      { breakpoint: 1980, options: { chart: { height: 400 } } },
      { breakpoint: 2500, options: { chart: { height: 470 } } },
      { breakpoint: 3000, options: { chart: { height: 450 } } },
    ],
  };

  return (
    <Fragment>
      <Row>
        <Card className="h-100 py-0">
          <Card.Header className="align-items-center card-header-height d-flex justify-content-between align-items-center py-0 my-0">
            <h4 className="mb-0 py-0 my-0">{title}</h4>
          </Card.Header>
          <Card.Body className="py-0">
            <ApexCharts options={SessionChartOptions} series={SessionChartSeries} type="line" />
          </Card.Body>
        </Card>
      </Row>
    </Fragment>
  );
};

export default AnSummary;
