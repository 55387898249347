// import node module libraries
import StatRightCenterIcon from "components/dashboard/common/stats/StatRightCenterIcon";
import { Fragment } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";

// import sub components
import CommonHeaderTabs from "../CommonHeaderTabs";

import { DashboardContext } from "context/Context";
import { collection, getDocs, getFirestore } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { functions } from "index";
import React, { useContext, useState } from "react";
import { Dropdown, ListGroup, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";

// import bootstrap icons
import { Calendar4 } from "react-bootstrap-icons";

const SalesForecast = () => {
  const [showModal, setShowModal] = useState(false);
  const [businessLocations, setBusinessLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState({});
  const [loading, setLoading] = useState(false);

  const [sugPriceMagnaLinear, setSugPriceMagnaLinear] = useState(0);
  const [sugPricePremiumLinear, setSugPricePremiumLinear] = useState(0);

  const [magnaEqLinear, setMagnaEqLinear] = useState({ slope: 0, intercept: 0, error: 0 });
  const [premiumEqLinear, setPremiumEqLinear] = useState({ slope: 0, intercept: 0, error: 0 });

  const [magnaLitersSoldLinear, setMagnaLitersSoldLinear] = useState(0);
  const [premiumLitersSoldLinear, setPremiumLitersSoldLinear] = useState(0);

  const [magnaProfitLinear, setMagnaProfitLinear] = useState(0);
  const [premiumProfitLinear, setPremiumProfitLinear] = useState(0);

  // States for Poly
  const [sugPriceMagnaPoly, setSugPriceMagnaPoly] = useState(0);
  const [sugPricePremiumPoly, setSugPricePremiumPoly] = useState(0);

  const [magnaEqPoly, setMagnaEqPoly] = useState({ slope: 0, intercept: 0, error: 0 });
  const [premiumEqPoly, setPremiumEqPoly] = useState({ slope: 0, intercept: 0, error: 0 });

  const [magnaLitersSoldPoly, setMagnaLitersSoldPoly] = useState(0);
  const [premiumLitersSoldPoly, setPremiumLitersSoldPoly] = useState(0);

  const [magnaProfitPoly, setMagnaProfitPoly] = useState(0);
  const [premiumProfitPoly, setPremiumProfitPoly] = useState(0);

  // Get the first day of the current month in YYYY-MM-DD format
  const getFirstDayOfMonth = () => {
    const now = new Date();
    const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
    return firstDay.toISOString().split("T")[0]; // Format as YYYY-MM-DD
  };

  // Get today's date in YYYY-MM-DD format
  const getToday = () => {
    const today = new Date();
    return today.toISOString().split("T")[0]; // Format as YYYY-MM-DD
  };

  const [startDate, setStartDate] = useState(getFirstDayOfMonth());
  const [endDate, setEndDate] = useState(getToday());
  const [magnaCost, setMagnaCost] = useState(0);
  const [premiumCost, setPremiumCost] = useState(0);
  const [method, setMethod] = useState("poly");

  const calculatePoly = httpsCallable(functions, "calculatePrices3");
  const calculateLinear = httpsCallable(functions, "calculatePrices");

  const db = getFirestore();
  const { user, company, setCompany } = useContext(DashboardContext);

  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="btn-icon btn btn-ghost btn-sm rounded-circle"
    >
      {children}
    </Link>
  ));

  const getLocs = async () => {
    var resp = [];
    const locsRef = collection(db, "companies", user.companyId, "locations");
    const loccSnap = await getDocs(locsRef);
    loccSnap.forEach((e) => {
      resp.push(e.data());
    });
    console.log(resp);
    if (resp.length >= 1) {
      setBusinessLocations(resp);
      setLoading(false);
    }
  };

  const ActionMenu = () => {
    return (
      <div>
        <Dropdown>
          <Dropdown.Toggle as={CustomToggle}>
            <i className="fe fe-more-vertical text-muted"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu align="end">
            {/* <Dropdown.Header>Settings</Dropdown.Header> */}
            <Dropdown.Item
              eventKey="1"
              onClick={(e) => {
                e.preventDefault();
                getLocs().then(() => {
                  setMethod("poly");
                  setShowModal(true);
                });
              }}
            >
              Realizar Cálculo
            </Dropdown.Item>

            <Dropdown.Item
              eventKey="1"
              onClick={(e) => {
                e.preventDefault();

                navigator.clipboard.writeText(
                  `Precio sugerido Magna: $${sugPriceMagnaPoly}\nLitros vendidos: ${magnaLitersSoldPoly}\nUtilidad diaria: $${magnaProfitPoly}\n\nPrecio sugerido Premium: $${sugPricePremiumPoly}\nLitros vendidos: ${premiumLitersSoldPoly}\nUtilidad diaria: $${premiumProfitPoly}`
                );
                toast.success("Copiado al portapapeles");
              }}
            >
              Copiar
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  };
  const ActionMenuLinear = () => {
    return (
      <div>
        <Dropdown>
          <Dropdown.Toggle as={CustomToggle}>
            <i className="fe fe-more-vertical text-muted"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu align="end">
            {/* <Dropdown.Header>Settings</Dropdown.Header> */}
            <Dropdown.Item
              eventKey="1"
              onClick={(e) => {
                e.preventDefault();
                getLocs().then(() => {
                  setMethod("linear");
                  setShowModal(true);
                });
              }}
            >
              Realizar Cálculo
            </Dropdown.Item>

            <Dropdown.Item
              eventKey="1"
              onClick={(e) => {
                e.preventDefault();

                navigator.clipboard.writeText(
                  `Precio sugerido Magna: $${sugPriceMagnaLinear}\nLitros vendidos: ${magnaLitersSoldLinear}\nUtilidad diaria: $${magnaProfitLinear}\n\nPrecio sugerido Premium: $${sugPricePremiumLinear}\nLitros vendidos: ${premiumLitersSoldLinear}\nUtilidad diaria: $${premiumProfitLinear}`
                );
                toast.success("Copiado al portapapeles");
              }}
            >
              Copiar
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  };

  const handleCalculate = async () => {
    setLoading(true);
    console.log("Calculando...");

    if (
      !startDate ||
      !endDate ||
      !selectedLocation?.value?.locId ||
      !magnaCost ||
      !premiumCost ||
      !user?.companyId
    ) {
      console.log("Faltan datos");
      toast.error("Por favor, llena todos los campos");
      setLoading(false);
      return;
    }

    const startD = new Date(startDate);
    const endD = new Date(endDate);

    // Common payload for API calls
    const commonPayload = {
      startDate: startD,
      endDate: endD,
      locationId: selectedLocation.value.locId,
      costMagna: Number(magnaCost),
      costPremium: Number(premiumCost),
      companyId: user.companyId,
    };

    try {
      const result =
        method === "linear"
          ? await calculateLinear(commonPayload)
          : await calculatePoly(commonPayload);

      if (method === "linear") {
        processLinearResults(result);
      } else {
        processPolyResults(result);
      }
    } catch (error) {
      console.error("Error during calculation:", error);
      toast.error("Error al calcular precios");
      resetStates(method);
      setLoading(false);
    }
  };

  // Process results for linear method
  const processLinearResults = (result) => {
    const magnaData = result.data.magna;
    const premiumData = result.data.premium;

    const magnaPrice = Math.round(magnaData.perfectPrice.suggestedPrice * 100) / 100;
    const premiumPrice = Math.round(premiumData.perfectPrice.suggestedPrice * 100) / 100;

    const magnaLiters = magnaPrice * magnaData.regression.slope + magnaData.regression.intercept;
    const premiumLiters =
      premiumPrice * premiumData.regression.slope + premiumData.regression.intercept;

    const magnaProfit = Math.max(0, magnaPrice * magnaLiters - magnaLiters * magnaCost);
    const premiumProfit = Math.max(0, premiumPrice * premiumLiters - premiumLiters * premiumCost);

    // Update states for Linear
    setSugPriceMagnaLinear(magnaPrice);
    setSugPricePremiumLinear(premiumPrice);

    console.log("equation : ", magnaData.regression);
    setMagnaEqLinear({
      slope: magnaData.regression.slope,
      intercept: magnaData.regression.intercept,
    });
    setPremiumEqLinear({
      slope: premiumData.regression.slope,
      intercept: premiumData.regression.intercept,
    });

    setMagnaLitersSoldLinear(Math.round(magnaLiters * 100) / 100);
    setPremiumLitersSoldLinear(Math.round(premiumLiters * 100) / 100);

    setMagnaProfitLinear(Math.round(magnaProfit * 100) / 100);
    setPremiumProfitLinear(Math.round(premiumProfit * 100) / 100);

    setLoading(false);
    setShowModal(false);
  };

  // Process results for polynomial method
  const processPolyResults = (result) => {
    const magnaData = result.data.magna;
    const premiumData = result.data.premium;

    const magnaPrice = Math.round(magnaData.perfectPrice.suggestedPrice * 100) / 100;
    const premiumPrice = Math.round(premiumData.perfectPrice.suggestedPrice * 100) / 100;

    const magnaLiters =
      magnaData.regression[0] * magnaPrice ** 2 +
      magnaData.regression[1] * magnaPrice +
      magnaData.regression[2];
    const premiumLiters =
      premiumData.regression[0] * premiumPrice ** 2 +
      premiumData.regression[1] * premiumPrice +
      premiumData.regression[2];

    const magnaProfit = Math.max(0, magnaPrice * magnaLiters - magnaLiters * magnaCost);
    const premiumProfit = Math.max(0, premiumPrice * premiumLiters - premiumLiters * premiumCost);

    // Update states for Poly
    setSugPriceMagnaPoly(magnaPrice);
    setSugPricePremiumPoly(premiumPrice);

    console.log("equation : ", magnaData.regression);
    setMagnaEqPoly(magnaData.regression);
    setPremiumEqPoly(premiumData.regression);

    setMagnaLitersSoldPoly(Math.round(magnaLiters * 100) / 100);
    setPremiumLitersSoldPoly(Math.round(premiumLiters * 100) / 100);

    setMagnaProfitPoly(Math.round(magnaProfit * 100) / 100);
    setPremiumProfitPoly(Math.round(premiumProfit * 100) / 100);

    setLoading(false);
    setShowModal(false);
  };

  // Reset states in case of errors
  const resetStates = (method) => {
    if (method === "linear") {
      setSugPriceMagnaLinear(0);
      setSugPricePremiumLinear(0);
      setMagnaEqLinear({ slope: 0, intercept: 0, error: 0 });
      setPremiumEqLinear({ slope: 0, intercept: 0, error: 0 });
      setMagnaLitersSoldLinear(0);
      setPremiumLitersSoldLinear(0);
      setMagnaProfitLinear(0);
      setPremiumProfitLinear(0);
    } else {
      setSugPriceMagnaPoly(0);
      setSugPricePremiumPoly(0);
      setMagnaEqPoly({ slope: 0, intercept: 0, error: 0 });
      setPremiumEqPoly({ slope: 0, intercept: 0, error: 0 });
      setMagnaLitersSoldPoly(0);
      setPremiumLitersSoldPoly(0);
      setMagnaProfitPoly(0);
      setPremiumProfitPoly(0);
    }
  };

  return (
    <Fragment>
      <Modal
        centered
        show={showModal}
        size="lg"
        onHide={() => {
          setShowModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Realizar Cálculo {method === "poly" ? "Compuesto" : "Simple"}</Modal.Title>
        </Modal.Header>
        {
          // Loading spinner
          loading ? (
            <div className="d-flex justify-content-center align-items-center">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <Modal.Body>
              <div className="d-flex justify-content-around align-items-center">
                <div>
                  <h5>Fecha inicial</h5>
                  <input
                    type="date"
                    value={startDate}
                    className="form-control"
                    onChange={(e) => {
                      console.log(e.target.value);
                      setStartDate(e.target.value);
                    }}
                  />
                </div>
                <div>
                  <h5>Fecha final</h5>
                  <input
                    type="date"
                    value={endDate}
                    className="form-control"
                    onChange={(e) => {
                      setEndDate(e.target.value);
                    }}
                  />
                </div>

                <div>
                  <h5 className="">Sucursal</h5>

                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    isClearable={false}
                    defaultValue={selectedLocation}
                    placeholder="Selecciona"
                    value={selectedLocation}
                    styles={{
                      control: (base) => ({
                        ...base,
                        height: "40px",
                        minHeight: "40px",
                        minWidth: "15rem",
                        borderRadius: "10px",
                        borderColor: "#ced4da",
                      }),
                    }}
                    // options={[
                    //   { value: user, label: user.email },
                    //   { value: "strawberry", label: "Strawberry" },
                    //   { value: "vanilla", label: "Vanilla" },
                    // ]}
                    options={businessLocations.map((e) => {
                      return { value: e, label: e.name };
                    })}
                    onChange={(e) => {
                      setSelectedLocation(e);
                    }}
                  />
                </div>
              </div>

              <div className="d-flex mt-4">
                <div className="mb-3 mx-3">
                  <label htmlFor="magnaCost" className="form-label text-success">
                    Costo de Gasolina Magna
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="magnaCost"
                    value={magnaCost}
                    onChange={(e) => setMagnaCost(e.target.value)}
                  />
                </div>
                <div className="mb-3 mx-3">
                  <label htmlFor="premiumCost" className="form-label text-danger">
                    Costo de Gasolina Premium
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="premiumCost"
                    value={premiumCost}
                    onChange={(e) => setPremiumCost(e.target.value)}
                  />
                </div>
              </div>
            </Modal.Body>
          )
        }

        <Modal.Footer>
          <button
            className="btn btn-primary"
            disabled={loading}
            onClick={() => {
              // console.log("Calculando...");
              handleCalculate();
            }}
          >
            Calcular
          </button>
        </Modal.Footer>
      </Modal>

      {/* page header tabs */}
      <CommonHeaderTabs />

      <Row>
        <Col xs={12} sm={12} md={12} xl={4} className="mb-4">
          {/* project summary section */}

          <Card className="h-100">
            <Card.Header className="card-header">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h4 className="mb-0">Pronóstico Compuesto</h4>
                </div>
                <div>
                  <ActionMenu />
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              <h4>
                {selectedLocation?.value?.name ? selectedLocation.value.name : "Realiza cálculo"}
              </h4>
              <p>
                {selectedLocation?.value?.fullAddress ? selectedLocation.value.fullAddress : "-"}
              </p>
              <ListGroup variant="flush">
                <ListGroup.Item className="px-0">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <Calendar4 size={16} className="text-primary" />
                      <div className="ms-2">
                        <h5 className="mb-0 text-body">Fecha de Inicio</h5>
                      </div>
                    </div>
                    <div>
                      <p className="text-dark mb-0 fw-semi-bold">{startDate}</p>
                    </div>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item className="px-0">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <Calendar4 size={16} className="text-primary" />
                      <div className="ms-2">
                        <h5 className="mb-0 text-body">Fecha de Término</h5>
                      </div>
                    </div>
                    <div>
                      <p className="text-dark mb-0 fw-semi-bold">{endDate}</p>
                    </div>
                  </div>
                </ListGroup.Item>
                {/* <ListGroup.Item className="px-0">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <Clock size={16} className="text-primary" />
                <div className="ms-2">
                  <h5 className="mb-0 text-body">Estimate Time</h5>
                </div>
              </div>
              <div>
                <p className="text-dark mb-0 fw-semi-bold">30 Days</p>
              </div>
            </div>
          </ListGroup.Item>
          <ListGroup.Item className="px-0">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <CurrencyDollar size={16} className="text-primary" />
                <div className="ms-2">
                  <h5 className="mb-0 text-body">Cost</h5>
                </div>
              </div>
              <div>
                <p className="text-dark mb-0 fw-semi-bold">$18,000</p>
              </div>
            </div>
          </ListGroup.Item> */}
              </ListGroup>

              <div className="d-flex mt-2">
                <div className="w-100 mx-2">
                  <h3 className="text-success mb-0">Magna</h3>
                  <ListGroup variant="flush">
                    <ListGroup.Item className="px-0">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <div className="ms-2">
                            <h5 className="mb-0 text-body">Costo</h5>
                          </div>
                        </div>
                        <div>
                          <p className="text-dark mb-0 fw-semi-bold">${magnaCost}</p>
                        </div>
                      </div>
                    </ListGroup.Item>

                    {/* <ListGroup.Item className="px-0">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <div className="ms-2">
                            <h5 className="mb-0 text-body">Slope</h5>
                          </div>
                        </div>
                        <div>
                          <p className="text-dark mb-0 fw-semi-bold">-0202</p>
                        </div>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item className="px-0">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <div className="ms-2">
                            <h5 className="mb-0 text-body">Intercept</h5>
                          </div>
                        </div>
                        <div>
                          <p className="text-dark mb-0 fw-semi-bold">22222</p>
                        </div>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item className="px-0">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <div className="ms-2">
                            <h5 className="mb-0 text-body">Error</h5>
                          </div>
                        </div>
                        <div>
                          <p className="text-dark mb-0 fw-semi-bold">22222</p>
                        </div>
                      </div>
                    </ListGroup.Item> */}
                  </ListGroup>
                </div>

                <div className="w-100 mx-2">
                  <h3 className="text-danger mb-0">Premium</h3>

                  <ListGroup variant="flush">
                    <ListGroup.Item className="px-0">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <div className="ms-2">
                            <h5 className="mb-0 text-body">Costo</h5>
                          </div>
                        </div>
                        <div>
                          <p className="text-dark mb-0 fw-semi-bold">${premiumCost}</p>
                        </div>
                      </div>
                    </ListGroup.Item>
                    {/* <ListGroup.Item className="px-0">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <div className="ms-2">
                            <h5 className="mb-0 text-body">Slope</h5>
                          </div>
                        </div>
                        <div>
                          <p className="text-dark mb-0 fw-semi-bold">-0202</p>
                        </div>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item className="px-0">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <div className="ms-2">
                            <h5 className="mb-0 text-body">Intercept</h5>
                          </div>
                        </div>
                        <div>
                          <p className="text-dark mb-0 fw-semi-bold">22222</p>
                        </div>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item className="px-0">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <div className="ms-2">
                            <h5 className="mb-0 text-body">Error</h5>
                          </div>
                        </div>
                        <div>
                          <p className="text-dark mb-0 fw-semi-bold">22222</p>
                        </div>
                      </div>
                    </ListGroup.Item> */}
                  </ListGroup>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} sm={12} md={12} xl={8} className="mb-4">
          {/* project budget section */}

          {loading ? (
            <div className="d-flex justify-content-center align-items-center">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <div>
              <Card>
                <Card.Header className="card-header">
                  <h4 className="mb-0 text-success">Magna </h4>
                </Card.Header>
                <Row>
                  <Col lg={4} md={12} xs={12}>
                    {/* <StatRightCenterIcon
                  title="Precio Sugerido"
                  value="$21.22"
                  iconName="dollar-sign"
                  iconColorVariant="primary"
                /> */}
                    <div className="d-flex align-items-center justify-content-between p-4">
                      <div>
                        <div className="d-flex align-items-center mb-1">
                          <h1 className="m-0 p-0">$</h1>
                          <input
                            type="number"
                            className="form-control  text-black"
                            id="priceMagna"
                            value={sugPriceMagnaPoly}
                            style={{ fontSize: "2em", fontWeight: "bold", lineHeight: "1.2" }} // Style similar to h2
                            onChange={(e) => {
                              console.log(e.target.value);
                              setSugPriceMagnaPoly(Number(e.target.value));
                              let total =
                                magnaEqPoly[0] * e.target.value ** 2 +
                                magnaEqPoly[1] * e.target.value +
                                magnaEqPoly[2];
                              setMagnaLitersSoldPoly(Math.round(total * 100) / 100);
                              let profit = Number(e.target.value) * total - total * magnaCost;

                              if (profit < 0) {
                                profit = 0;
                              }
                              setMagnaProfitPoly(Math.round(profit * 100) / 100);
                            }}
                          />
                        </div>

                        <p className="mb-0 ">Precio Sugerido</p>
                      </div>
                      <div className="ms-3">
                        <div
                          className={`icon-shape icon-lg bg-light-primary text-primary rounded-circle`}
                        >
                          <i className={`fe fe-dollar-sign fs-3`}></i>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={4} md={12} xs={12} className="border-start-md">
                    <StatRightCenterIcon
                      title="Litros Vendidos"
                      value={magnaLitersSoldPoly.toLocaleString()}
                      iconName="droplet"
                      iconColorVariant="info"
                    />
                  </Col>
                  <Col lg={4} md={12} xs={12} className="border-start-md">
                    <StatRightCenterIcon
                      title="Utilidad Diaria"
                      value={"$" + magnaProfitPoly.toLocaleString()}
                      iconName="briefcase"
                      iconColorVariant="success"
                    />
                  </Col>
                </Row>
              </Card>
              <br />
              <Card>
                <Card.Header className="card-header">
                  <h4 className="mb-0 text-danger">Premium </h4>
                </Card.Header>
                <Row>
                  <Col lg={4} md={12} xs={12}>
                    <div className="d-flex align-items-center justify-content-between p-4">
                      <div>
                        <div className="d-flex align-items-center mb-1">
                          <h1 className="m-0 p-0">$</h1>
                          <input
                            type="number"
                            className="form-control  text-black"
                            id="pricePremium"
                            value={sugPricePremiumPoly}
                            style={{ fontSize: "2em", fontWeight: "bold", lineHeight: "1.2" }} // Style similar to h2
                            onChange={(e) => {
                              console.log(e.target.value);
                              setSugPricePremiumPoly(Number(e.target.value));
                              let total =
                                premiumEqPoly[0] * e.target.value ** 2 +
                                premiumEqPoly[1] * e.target.value +
                                premiumEqPoly[2];
                              setPremiumLitersSoldPoly(Math.round(total * 100) / 100);
                              let profit = Number(e.target.value) * total - total * premiumCost;
                              if (profit < 0) {
                                profit = 0;
                              }
                              setPremiumProfitPoly(Math.round(profit * 100) / 100);
                            }}
                          />
                        </div>

                        <p className="mb-0 ">Precio Sugerido</p>
                      </div>
                      <div className="ms-3">
                        <div
                          className={`icon-shape icon-lg bg-light-primary text-primary rounded-circle`}
                        >
                          <i className={`fe fe-dollar-sign fs-3`}></i>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={4} md={12} xs={12} className="border-start-md">
                    <StatRightCenterIcon
                      title="Litros Vendidos"
                      value={premiumLitersSoldPoly.toLocaleString()}
                      iconName="droplet"
                      iconColorVariant="info"
                    />
                  </Col>
                  <Col lg={4} md={12} xs={12} className="border-start-md">
                    <StatRightCenterIcon
                      title="Utilidad Diaria"
                      value={"$" + premiumProfitPoly.toLocaleString()}
                      iconName="briefcase"
                      iconColorVariant="success"
                    />
                  </Col>
                </Row>
              </Card>
            </div>
          )}
        </Col>
      </Row>

      <hr />
      <br />
      <Row>
        <Col xs={12} sm={12} md={12} xl={4} className="mb-4">
          {/* project summary section */}

          <Card className="h-100">
            <Card.Header className="card-header">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h4 className="mb-0">Pronóstico Simple</h4>
                </div>
                <div>
                  <ActionMenuLinear />
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              <h4>
                {selectedLocation?.value?.name ? selectedLocation.value.name : "Realiza cálculo"}
              </h4>
              <p>
                {selectedLocation?.value?.fullAddress ? selectedLocation.value.fullAddress : "-"}
              </p>
              <ListGroup variant="flush">
                <ListGroup.Item className="px-0">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <Calendar4 size={16} className="text-primary" />
                      <div className="ms-2">
                        <h5 className="mb-0 text-body">Fecha de Inicio</h5>
                      </div>
                    </div>
                    <div>
                      <p className="text-dark mb-0 fw-semi-bold">{startDate}</p>
                    </div>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item className="px-0">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <Calendar4 size={16} className="text-primary" />
                      <div className="ms-2">
                        <h5 className="mb-0 text-body">Fecha de Término</h5>
                      </div>
                    </div>
                    <div>
                      <p className="text-dark mb-0 fw-semi-bold">{endDate}</p>
                    </div>
                  </div>
                </ListGroup.Item>
              </ListGroup>

              <div className="d-flex mt-2">
                <div className="w-100 mx-2">
                  <h3 className="text-success mb-0">Magna</h3>
                  <ListGroup variant="flush">
                    <ListGroup.Item className="px-0">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <div className="ms-2">
                            <h5 className="mb-0 text-body">Costo</h5>
                          </div>
                        </div>
                        <div>
                          <p className="text-dark mb-0 fw-semi-bold">${magnaCost}</p>
                        </div>
                      </div>
                    </ListGroup.Item>
                  </ListGroup>
                </div>

                <div className="w-100 mx-2">
                  <h3 className="text-danger mb-0">Premium</h3>

                  <ListGroup variant="flush">
                    <ListGroup.Item className="px-0">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <div className="ms-2">
                            <h5 className="mb-0 text-body">Costo</h5>
                          </div>
                        </div>
                        <div>
                          <p className="text-dark mb-0 fw-semi-bold">${premiumCost}</p>
                        </div>
                      </div>
                    </ListGroup.Item>
                  </ListGroup>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} sm={12} md={12} xl={8} className="mb-4">
          {/* project budget section */}

          {loading ? (
            <div className="d-flex justify-content-center align-items-center">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <div>
              <Card>
                <Card.Header className="card-header">
                  <h4 className="mb-0 text-success">Magna </h4>
                </Card.Header>
                <Row>
                  <Col lg={4} md={12} xs={12}>
                    <div className="d-flex align-items-center justify-content-between p-4">
                      <div>
                        <div className="d-flex align-items-center mb-1">
                          <h1 className="m-0 p-0">$</h1>
                          <input
                            type="number"
                            className="form-control  text-black"
                            id="priceMagna"
                            value={sugPriceMagnaLinear}
                            style={{ fontSize: "2em", fontWeight: "bold", lineHeight: "1.2" }} // Style similar to h2
                            onChange={(e) => {
                              console.log(e.target.value);
                              setSugPriceMagnaLinear(Number(e.target.value));
                              let total =
                                e.target.value * magnaEqLinear.slope + magnaEqLinear.intercept;

                              setMagnaLitersSoldLinear(Math.round(total * 100) / 100);
                              let profit = Number(e.target.value) * total - total * magnaCost;

                              if (profit < 0) {
                                profit = 0;
                              }
                              setMagnaProfitLinear(Math.round(profit * 100) / 100);
                            }}
                          />
                        </div>

                        <p className="mb-0 ">Precio Sugerido</p>
                      </div>
                      <div className="ms-3">
                        <div
                          className={`icon-shape icon-lg bg-light-primary text-primary rounded-circle`}
                        >
                          <i className={`fe fe-dollar-sign fs-3`}></i>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={4} md={12} xs={12} className="border-start-md">
                    <StatRightCenterIcon
                      title="Litros Vendidos"
                      value={magnaLitersSoldLinear.toLocaleString()}
                      iconName="droplet"
                      iconColorVariant="info"
                    />
                  </Col>
                  <Col lg={4} md={12} xs={12} className="border-start-md">
                    <StatRightCenterIcon
                      title="Utilidad Diaria"
                      value={"$" + magnaProfitLinear.toLocaleString()}
                      iconName="briefcase"
                      iconColorVariant="success"
                    />
                  </Col>
                </Row>
              </Card>
              <br />
              <Card>
                <Card.Header className="card-header">
                  <h4 className="mb-0 text-danger">Premium </h4>
                </Card.Header>
                <Row>
                  <Col lg={4} md={12} xs={12}>
                    <div className="d-flex align-items-center justify-content-between p-4">
                      <div>
                        <div className="d-flex align-items-center mb-1">
                          <h1 className="m-0 p-0">$</h1>
                          <input
                            type="number"
                            className="form-control  text-black"
                            id="pricePremium"
                            value={sugPricePremiumLinear}
                            style={{ fontSize: "2em", fontWeight: "bold", lineHeight: "1.2" }} // Style similar to h2
                            onChange={(e) => {
                              console.log(e.target.value);
                              setSugPricePremiumLinear(Number(e.target.value));
                              let total =
                                e.target.value * premiumEqLinear.slope + premiumEqLinear.intercept;

                              setPremiumLitersSoldLinear(Math.round(total * 100) / 100);
                              let profit = Number(e.target.value) * total - total * premiumCost;
                              if (profit < 0) {
                                profit = 0;
                              }
                              setPremiumProfitLinear(Math.round(profit * 100) / 100);
                            }}
                          />
                        </div>

                        <p className="mb-0 ">Precio Sugerido</p>
                      </div>
                      <div className="ms-3">
                        <div
                          className={`icon-shape icon-lg bg-light-primary text-primary rounded-circle`}
                        >
                          <i className={`fe fe-dollar-sign fs-3`}></i>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={4} md={12} xs={12} className="border-start-md">
                    <StatRightCenterIcon
                      title="Litros Vendidos"
                      value={premiumLitersSoldLinear.toLocaleString()}
                      iconName="droplet"
                      iconColorVariant="info"
                    />
                  </Col>
                  <Col lg={4} md={12} xs={12} className="border-start-md">
                    <StatRightCenterIcon
                      title="Utilidad Diaria"
                      value={"$" + premiumProfitLinear.toLocaleString()}
                      iconName="briefcase"
                      iconColorVariant="success"
                    />
                  </Col>
                </Row>
              </Card>
            </div>
          )}
        </Col>
      </Row>

      <ToastContainer position="bottom-right" />
    </Fragment>
  );
};

export default SalesForecast;
