// import node module libraries
import { DashboardContext } from "context/Context";

import { deleteDoc, doc, getFirestore } from "firebase/firestore";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Button, Card, Col, Dropdown, Modal, Row, Spinner } from "react-bootstrap";
import { ChevronLeft, ChevronRight, MoreVertical, Trash } from "react-feather";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
// import sub components
// import JobListingGridviewCard from "components/marketing/common/cards/JobListingGridviewCard";
import { toast, ToastContainer } from "react-toastify";

// import data files

const RivalGridView = ({ data }) => {
  const [Records, setRecords] = useState(data);
  const [loading, setLoading] = useState(false);
  const [selectedLoc, setSelected] = useState(null);
  const [modal, setModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const RecordsPerPage = 12;
  const db = getFirestore();
  const { user, company, setCompany } = useContext(DashboardContext);
  const pagesVisited = pageNumber * RecordsPerPage;
  const pageCount = Math.ceil(Records.length / RecordsPerPage);
  const color = ["primary", "warning", "success", "info", "secondary"];

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="btn-icon btn btn-ghost btn-sm rounded-circle shadow border-primary"
    >
      {children}
    </Link>
  ));
  const ActionMenu = ({ loc }) => {
    return (
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle}>
          <MoreVertical size="15px" className="text-secondary" />
        </Dropdown.Toggle>
        <Dropdown.Menu align="end">
          {/* <Dropdown.Header>SETTINGS</Dropdown.Header> */}
          {/* <Dropdown.Item eventKey="1">
            {" "}
            <Edit size="15px" className="dropdown-item-icon" /> Editar
          </Dropdown.Item> */}
          <Dropdown.Item
            eventKey="2"
            className="text-danger"
            onClick={(e) => {
              e.preventDefault();
              setSelected(loc);
              setModal(true);
            }}
          >
            {" "}
            <Trash size="15px" className="dropdown-item-icon text-danger" /> Eliminar
          </Dropdown.Item>
          <Dropdown.Item eventKey="3">
            <Link to={`/rivals/rival/${loc.id}`} className="text-decoration-none">
              Ver detalle {">>"}
            </Link>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const deleteLoc = async () => {
    if (company.locationCount > 1) {
      setLoading(true);
      await deleteDoc(doc(db, "companies", user.companyId, "rivals", selectedLoc.locId))
        .then(() => {
          setLoading(false);
          setModal(false);
          var rec = Records;
          var sIndex = Records.indexOf(selectedLoc);
          rec.splice(sIndex, 1);
          setRecords(rec);
          var cc = company;
          cc.locationCount = cc.locationCount - 1;
          setCompany(cc);
          toast.success("Rival eliminado");
        })
        .catch((er) => {
          // alert(er);
          setModal(false);
          toast.error("Ha ocurrido un error");
        });
    } else {
      setModal(false);
      toast.error("No puedes borrar tu única sucursal");
    }
  };
  useEffect(() => {}, [data]);

  const displayRecords = Records.slice(pagesVisited, pagesVisited + RecordsPerPage).map(
    (item, index) => {
      return (
        <Col md={6} lg={4} xs={12} className="mb-4" key={index}>
          <Modal show={modal} centered onHide={() => setModal(false)}>
            {loading ? (
              <Modal.Body className="py-5 d-flex align-items-center justify-content-center">
                <Spinner animation="border" variant="primary" className="me-2" />
              </Modal.Body>
            ) : (
              <div>
                <Modal.Header>
                  <h2>¿Estás Seguro?</h2>
                </Modal.Header>

                <Modal.Body>
                  {selectedLoc && (
                    <p className="fw-semi-bold">
                      Estas a punto de <span className="text-danger">eliminar</span> la sucursal "
                      {selectedLoc.name}" con dirección de "{selectedLoc.location}".
                    </p>
                  )}
                </Modal.Body>

                <Modal.Footer>
                  <Button
                    variant="primary"
                    onClick={(e) => {
                      e.preventDefault();
                      deleteLoc();
                    }}
                  >
                    Confirmar
                  </Button>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      setModal(false);
                    }}
                    variant="outline-danger"
                    className="ms-2"
                  >
                    Cancelar
                  </Button>
                </Modal.Footer>
              </div>
            )}
          </Modal>
          <Fragment>
            <Card className="card-bordered card-hover  h-100">
              <Card.Body>
                {/* <div className="mb-3 mb-md-0">
                 <Image src={item.logo} alt="" className="icon-shape border rounded-circle" /> 
              </div> */}
                <div className="w-100 mt-1">
                  <div className=" mb-4">
                    <div className="d-flex justify-content-between mb-3">
                      <h3 className="mb-2 fs-4">
                        {item.name}
                        {/* {item.featured && <span className="badge bg-danger-soft">Featured Job</span>} */}
                      </h3>
                      {user.permission >= 4 && <ActionMenu loc={item} />}
                    </div>

                    <a href={item.crawlLink} target="_blank" rel="noreferrer">
                      {item.crawlLink}
                    </a>

                    {item.premiumPrice ? (
                      <div className="mt-2">
                        <p className="mb-0 pb-0">
                          Precios actualizados el{" "}
                          {new Date(item.crawlDate.seconds * 1000).toLocaleDateString("es-MX")}
                        </p>
                        <p className="text-success mb-0 pb-1">Magna: {item.magnaPrice}</p>
                        <p className="text-danger">Premium: {item.premiumPrice}</p>
                      </div>
                    ) : (
                      <p className="">Precios no disponibles por el momento</p>
                    )}
                  </div>
                </div>
                <div>
                  <div className="mb-4">
                    <div className="mb-2 mb-md-0">
                      {item.fullAddress && (
                        <div className="mt-1">
                          <i className="fe fe-map-pin text-muted"></i>
                          <span className="ms-1 ">{item.fullAddress}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <i className="fe fe-clock text-muted"></i> Creada el{" "}
                    <span>{new Date(item.dateCreated.seconds * 1000).toLocaleString()}</span>
                    <br />
                    <i className="fe fe-info text-muted"></i> Creada por{" "}
                    <span>{item.createdBy.name + " " + item.createdBy.lastName}</span>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Fragment>
        </Col>
      );
    }
  );
  // end of paging setup

  return (
    <Fragment>
      <Row>
        {displayRecords.length > 0 ? displayRecords : <Col>No matching records found.</Col>}
      </Row>
      <ReactPaginate
        previousLabel={<ChevronLeft size="14px" />}
        nextLabel={<ChevronRight size="14px" />}
        pageCount={pageCount}
        onPageChange={changePage}
        containerClassName={"justify-content-center mb-0 pagination"}
        previousLinkClassName={"page-link mx-1 rounded"}
        nextLinkClassName={"page-link mx-1 rounded"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link mx-1 rounded"}
        disabledClassName={"paginationDisabled"}
        activeClassName={"active"}
      />
      <ToastContainer position="bottom-right" />
    </Fragment>
  );
};

export default RivalGridView;
