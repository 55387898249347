// Import necessary node module libraries
import { collection, getDocs, getFirestore, query, where } from "firebase/firestore";
import { Fragment, useContext, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
// Import subcomponents
import Calendar from "components/calendar/Calendar";
import { DashboardContext } from "context/Context";
import Select from "react-select";
import RivSummary from "./RivSummary";

const RivalCalendar = () => {
  const db = getFirestore();

  const { user } = useContext(DashboardContext);

  const [data, setData] = useState([]);
  const [dataRival, setDataRival] = useState([]);
  const [businessLocations, setBusinessLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [change, setChange] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());

  const [magnaProfits, setMagnaProfits] = useState([]);
  const [premiumProfits, setPremiumProfits] = useState([]);
  const [magnaLiters, setMagnaLiters] = useState([]);
  const [premiumLiters, setPremiumLiters] = useState([]);
  const [magnaSales, setMagnaSales] = useState([]);
  const [premiumSales, setPremiumSales] = useState([]);
  const [dateSArray, setDateSArray] = useState([]);
  const [magnaPrices, setMagnaPrices] = useState([]);
  const [premiumPrices, setPremiumPrices] = useState([]);
  const [magnaCosts, setMagnaCosts] = useState([]);
  const [premiumCosts, setPremiumCosts] = useState([]);
  const [rivalMagnaPrices, setRivalMagnaPrices] = useState([]);
  const [rivalPremiumPrices, setRivalPremiumPrices] = useState([]);
  const [rivalAlias, setRivalAlias] = useState("");

  // Fetch data from Firestore
  const fetchData = async (collectionName, startOfMonth, endOfMonth) => {
    const colRef = collection(db, "companies", user.companyId, collectionName);
    const q = query(colRef, where("date", ">=", startOfMonth), where("date", "<=", endOfMonth));
    const snapshot = await getDocs(q);
    return snapshot.docs.map((doc) => doc.data());
  };

  // Fetch locations from Firestore
  const fetchLocations = async () => {
    const colRef = collection(db, "companies", user.companyId, "locations");
    const snapshot = await getDocs(colRef);
    return snapshot.docs.map((doc) => ({ locId: doc.id, ...doc.data() }));
  };

  const loadData = async () => {
    setLoading(true);
    const startOfMonth = new Date(new Date().getFullYear(), currentMonth, 1);
    const endOfMonth = new Date(new Date().getFullYear(), currentMonth + 1, 0);
    startOfMonth.setHours(0, 0, 0, 0);
    endOfMonth.setHours(23, 59, 59, 999);

    const [data, dataRival, locations] = await Promise.all([
      fetchData("dayInformation", startOfMonth, endOfMonth),
      fetchData("rivalPrices", startOfMonth, endOfMonth),
      fetchLocations(),
    ]);

    setData(data);
    console.log("rival data", dataRival);
    setDataRival(dataRival);
    setBusinessLocations(
      locations.map((location) => ({
        value: location,
        label: location.name,
      }))
    );
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, [currentMonth, change]);

  useEffect(() => {
    if (!selectedLocation || data.length === 0) return;

    const mProfits = [];
    const pProfits = [];
    const mLiters = [];
    const pLiters = [];
    const mSales = [];
    const pSales = [];
    const mPrices = [];
    const pPrices = [];
    const mCosts = [];
    const pCosts = [];
    const rivalMagna = [];
    const rivalPremium = [];
    const dsArray = [];

    // Extract rivalId from the URL
    let rivalId = window.location.pathname.split("/")[3];

    // Convert dataRival into a dictionary for quick date lookup
    const rivalDataMap = dataRival.reduce((acc, rivalEntry) => {
      const rivalDate = new Date(rivalEntry.date.seconds * 1000).toLocaleDateString("es-MX");
      acc[rivalDate] = rivalEntry;
      return acc;
    }, {});

    data.forEach((entry) => {
      const entryDate = new Date(entry.date.seconds * 1000).toLocaleDateString("es-MX");
      const rivalEntry = rivalDataMap[entryDate]; // Match rival data by date

      // Safely access location data
      const locationId = selectedLocation?.value?.locId;
      const locationData = locationId ? entry[locationId] : undefined;

      if (locationData?.magnaSalesIncome) {
        mProfits.push(
          locationData.magnaSalesIncome - locationData.magnaLitersSold * entry.magnaCost
        );
        mLiters.push(locationData.magnaLitersSold);
        mSales.push(locationData.magnaSalesIncome);
        mPrices.push(locationData.magnaPrice);
        mCosts.push(entry.magnaCost);
        dsArray.push(entryDate);

        // Add rival Magna prices if available
        if (rivalEntry?.[rivalId]?.magnaPrice !== undefined) {
          rivalMagna.push(rivalEntry[rivalId].magnaPrice);
          setRivalAlias(rivalEntry[rivalId].alias);
        } else {
          rivalMagna.push(null); // Placeholder for missing rival data
        }
      }

      if (locationData?.premiumSalesIncome) {
        pProfits.push(
          locationData.premiumSalesIncome - locationData.premiumLitersSold * entry.premiumCost
        );
        pLiters.push(locationData.premiumLitersSold);
        pSales.push(locationData.premiumSalesIncome);
        pPrices.push(locationData.premiumPrice);
        pCosts.push(entry.premiumCost);

        // Add rival Premium prices if available
        if (rivalEntry?.[rivalId]?.premiumPrice !== undefined) {
          rivalPremium.push(rivalEntry[rivalId].premiumPrice);
        } else {
          rivalPremium.push(null); // Placeholder for missing rival data
        }
      }
    });

    setMagnaProfits(mProfits);
    setPremiumProfits(pProfits);
    setMagnaLiters(mLiters);
    setPremiumLiters(pLiters);
    setMagnaSales(mSales);
    setPremiumSales(pSales);
    setDateSArray(dsArray);
    setMagnaPrices(mPrices);
    setPremiumPrices(pPrices);
    setMagnaCosts(mCosts);
    setPremiumCosts(pCosts);
    setRivalMagnaPrices(rivalMagna);
    setRivalPremiumPrices(rivalPremium);
  }, [selectedLocation, data, dataRival]);

  return (
    <Fragment>
      <Card className="mb-4">
        <div className="w-100 p-3">
          <div className="d-flex align-items-baseline justify-content-between">
            {!loading && businessLocations.length > 0 && (
              <div>
                <h5 className="mb-0 card-title me-4">Selecciona la ubicación</h5>
                <Select
                  className="react-select-container"
                  classNamePrefix="react-select"
                  placeholder="Selecciona"
                  value={selectedLocation}
                  options={businessLocations}
                  onChange={(e) => setSelectedLocation(e)}
                />
              </div>
            )}
          </div>
        </div>

        <Calendar
          data={data}
          selectedLocation={selectedLocation?.value}
          callbackFn={() => setChange(!change)}
          currentMonthFn={(res) => setCurrentMonth(res)}
        />
      </Card>

      <RivSummary
        ventaData={magnaSales}
        litrosData={magnaLiters}
        utilidadData={magnaProfits}
        xAxisData={dateSArray}
        priceData={magnaPrices}
        costData={magnaCosts}
        rivalPriceData={rivalMagnaPrices}
        title={`Magna - ${rivalAlias}`}
      />

      <br />

      <RivSummary
        ventaData={premiumSales}
        litrosData={premiumLiters}
        utilidadData={premiumProfits}
        xAxisData={dateSArray}
        priceData={premiumPrices}
        costData={premiumCosts}
        rivalPriceData={rivalPremiumPrices}
        title={`Premium - ${rivalAlias}`}
      />

      <Row>
        <Col lg={6} className="mb-4">
          {/* Placeholder */}
        </Col>
        <Col lg={6} className="mb-4">
          {/* Placeholder */}
        </Col>
      </Row>
    </Fragment>
  );
};

export default RivalCalendar;
