// Import node module libraries
import { Fragment } from "react";
import { Card, Row } from "react-bootstrap";

// Import custom components
import ApexCharts from "components/elements/charts/ApexCharts";

const RivSummary = ({
  ventaData,
  litrosData,
  utilidadData,
  priceData,
  costData,
  rivalPriceData, // Add rival price data
  xAxisData,
  title,
}) => {
  const theme = {
    primary: "var(--geeks-primary)",
    danger: "var(--geeks-danger)",
    purple: "#6f42c1",
    orange: "#f39c12",
    info: "var(--geeks-info)",
    gray500: "var(--geeks-gray-500)",
    navy: "#1f77b4",
    teal: "#2ca02c",
    success: "var(--geeks-success)",
    borderColor: "var(--geeks-border-color)",
    blue: "#007bff",
    green: "#28a745",
    black: "#000000", // Black for rival prices
  };

  const SessionChartSeries = [
    {
      name: "Litros",
      data: litrosData,
      color: theme.orange,
    },
    {
      name: "Utilidad",
      data: utilidadData,
      color: theme.success,
    },
    {
      name: "Costo",
      data: costData,
      color: theme.danger,
    },
    {
      name: "Precio",
      data: priceData,
      color: theme.blue,
    },
    {
      name: "Precio Rival",
      data: rivalPriceData,
      color: theme.black,
    },
  ];

  const SessionChartOptions = {
    chart: {
      toolbar: { show: false },
      height: 200,
      type: "line",
      zoom: { enabled: false },
    },
    dataLabels: { enabled: false },
    stroke: {
      width: [3, 3, 3, 3, 3],
      curve: "smooth",
      dashArray: [0, 0, 0, 0, 5], // Dashed line for rival prices
    },
    legend: { show: true },
    colors: [theme.orange, theme.success, theme.danger, theme.blue, theme.black],
    markers: { size: 3, hover: { sizeOffset: 6 } },
    xaxis: {
      categories: xAxisData,
      labels: {
        style: {
          colors: theme.gray500,
          fontSize: "12px",
          fontFamily: "Inter",
          cssClass: "apexcharts-xaxis-label",
        },
      },
      axisBorder: {
        show: true,
        color: theme.borderColor,
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: theme.borderColor,
        height: 6,
      },
    },
    yaxis: [
      {
        title: {
          text: "Litros",
          style: { color: theme.orange },
        },
        labels: {
          style: { colors: theme.orange },
          formatter: (value) => Math.round(value),
        },
        min: Math.min(...litrosData) - 5000, // Set min value
        max: Math.max(...utilidadData) + 5000, // Set max value
      },
      {
        opposite: true,
        title: {
          text: "Utilidad",
          style: { color: theme.success },
        },
        labels: {
          style: { colors: theme.success },
          formatter: (value) => Math.round(value),
        },
        min: Math.min(...litrosData) - 5000, // Set min value
        max: Math.max(...utilidadData) + 5000, // Set max value
      },
      {
        title: {
          text: "Costo",
          style: { color: theme.danger },
        },
        labels: {
          style: { colors: theme.danger },
          formatter: (value) => value.toFixed(3),
        },
        min: Math.min(...costData) - 0.5, // Set min value
        max: Math.max(...priceData) + 0.5, // Set max value
        // tickAmount: 10, // Increase the number of ticks for better visibility
        forceNiceScale: true, // Force nice scale
      },
      {
        opposite: true,
        title: {
          text: "Precio",
          style: { color: theme.blue },
        },
        labels: {
          style: { colors: theme.blue },
          formatter: (value) => value.toFixed(3),
        },
        min: Math.min(...costData) - 0.5, // Set min value
        max: Math.max(...priceData) + 2.5, // Set max value
        // tickAmount: 10, // Increase the number of ticks for better visibility
        forceNiceScale: true, // Force nice scale
      },
      {
        opposite: true,
        title: {
          text: "Precio Rival",
          style: { color: theme.black },
        },
        labels: {
          style: { colors: theme.black },
          formatter: (value) => value.toFixed(3),
        },
        min: Math.min(...costData) - 0.5, // Set min value
        max: Math.max(...priceData) + 2.5, // Set max value
        // tickAmount: 10, // Increase the number of ticks for better visibility
        forceNiceScale: true, // Force nice scale
      },
    ],
    tooltip: {
      y: {
        formatter: (val) => Math.round(val * 1000) / 1000,
      },
    },
    grid: { borderColor: theme.borderColor },
    responsive: [
      { breakpoint: 480, options: { chart: { height: 300 } } },
      { breakpoint: 1441, options: { chart: { height: 360 } } },
      { breakpoint: 1980, options: { chart: { height: 400 } } },
      { breakpoint: 2500, options: { chart: { height: 470 } } },
      { breakpoint: 3000, options: { chart: { height: 450 } } },
    ],
  };

  return (
    <Fragment>
      <Row>
        <Card className="h-100 py-0">
          <Card.Header className="align-items-center card-header-height d-flex justify-content-between align-items-center py-0 my-0">
            <h4 className="mb-0 py-0 my-0">{title}</h4>
          </Card.Header>
          <Card.Body className="py-0">
            <ApexCharts options={SessionChartOptions} series={SessionChartSeries} type="line" />
          </Card.Body>
        </Card>
      </Row>
    </Fragment>
  );
};

export default RivSummary;
