// import node module libraries
import { mdiFileDownload, mdiFilePlus } from "@mdi/js";
import Icon from "@mdi/react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { Fragment, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import QuizProgress from "./QuizProgress";

const RegistrationProcess = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const auth = getAuth();
  const nPages = 3;
  const db = getFirestore();
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const [companyName, setCompany] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [template, setTemplate] = useState(null);
  const [userInfo, setUserInfo] = useState({});

  const checkIfValid = (value) => {
    console.log(value);
    if (
      value != null &&
      value !== "" &&
      value !== " " &&
      value !== undefined &&
      value.trim().length >= 5 &&
      /^[a-zA-ZÁáÉéÍíÓóÚú.ÑñüÜ\s]+$/g.test(value)
    ) {
      console.log(true);

      return true;
    } else {
      console.log(false);
      return false;
    }
  };
  const nextPage = () => {
    if (currentPage !== nPages) setCurrentPage(currentPage + 1);
  };
  const prevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };

  const terminar = async () => {
    const user = auth.currentUser;

    var results = [];
    var compId = "";
    const q = query(collection(db, "companies"), where("ownerId", "==", user.uid));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      // console.log(doc.id, " => ", doc.data());
      results.push(doc.data());
    });

    if (results.length >= 1) {
      console.log("negocio ya creado");
    } else {
      const companyRef = await addDoc(collection(db, "companies"), {
        companyName: companyName,
        dateCreated: new Date(),
        ownerId: user.uid,
        ownerEmail: user.email,
        ownerName: name,
        members: [
          {
            name: userInfo.name,
            lastName: userInfo.lastName,
            email: userInfo.email,
            permission: 4,
            uid: userInfo.uid,
          },
        ],
      }).catch((er) => {
        alert("1 " + er);
      });
      compId = companyRef.id;
      const updateRef = doc(db, "companies", compId);
      await setDoc(updateRef, { companyId: compId }, { merge: true }).catch((er) => {
        alert("2 " + er);
      });
    }

    await setDoc(
      doc(db, "users", user.uid),
      {
        companyId: compId,
        companyName: companyName,
        completeName: name,
        registrationComplete: true,
        permission: 4,
      },
      { merge: true }
    )
      .then(() => {
        navigate("/");
      })
      .catch((er) => {
        alert("3 " + er);
      });
  };

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        // ...
        const { emailVerified } = user;

        if (emailVerified) {
          const docRef = doc(db, "users", uid);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            setName(docSnap.data().completeName ? docSnap.data().completeName : "");
            setCompany(docSnap.data().companyName ? docSnap.data().companyName : "");
            setUserInfo(docSnap.data());
          } else {
            // docSnap.data() will be undefined in this case
            console.log("No such document!");
            navigate("/landing");
          }
        }
      } else {
        // User is signed out
        navigate("/landing");
      }
    });
  }, []);

  useEffect(() => {
    console.log(currentPage);
    switch (currentPage) {
      case 1:
        setDisabled(!checkIfValid(name) || !checkIfValid(companyName));
    }
  }, [currentPage, name, companyName]);

  return (
    <Fragment>
      {/* Progress */}
      <QuizProgress currentQuestion={currentPage} totalQuestion={nPages} />

      {/* Pasos del proceso */}
      <div className="mt-5">
        {currentPage == 1 ? (
          <div>
            <h3 className="mb-3">Datos para contrato de prestación de servicios</h3>

            <Form>
              <Row>
                <Col lg={12} md={12} className="mb-3">
                  {/* User Name */}
                  <Form.Label>Nombre Completo*</Form.Label>
                  <Form.Control
                    type="text"
                    id="name"
                    placeholder="Nombre"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    required
                  />
                </Col>

                <Col lg={12} md={12} className="mb-3">
                  {/* email */}
                  <Form.Label>
                    Nombre del Negocio* <br />
                    <small>(Razón Social)</small>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="comp"
                    value={companyName}
                    placeholder="EJEMPLO S.A de C.V"
                    onChange={(e) => {
                      setCompany(e.target.value);
                    }}
                    required
                  />
                </Col>
              </Row>
            </Form>
          </div>
        ) : currentPage == 2 ? (
          <Fragment>
            <h3 className="mb-3">Confirmación & Pago</h3>
            <p>Confirma el plan que seleccionaste</p>
          </Fragment>
        ) : currentPage == 3 ? (
          <Fragment>
            <h3 className="mb-3">Elige cómo iniciar</h3>
            <p>Decide si iniciar con una plantilla o iniciar en blanco.</p>

            <div className="d-flex justify-content-around">
              <Form.Check
                type="radio"
                id={`radio-plantilla`}
                bsPrefix="d-grid"
                className="mb-2 w-100 ms-2"
              >
                <Form.Check.Input
                  onChange={() => setTemplate("template")}
                  checked={template == "template"}
                  type="radio"
                  className="btn-check"
                  name={"answer-plantilla"}
                />

                <Form.Check.Label className="btn btn-outline-light-primary text-dark-primary text-center d-flex flex-column align-items-center">
                  <Icon path={mdiFileDownload} size={3} />
                  Plantilla Gasolinera
                </Form.Check.Label>
              </Form.Check>

              <Form.Check
                type="radio"
                id={`radio-blanco`}
                bsPrefix="d-grid"
                className="mb-2 w-100 ms-2"
              >
                <Form.Check.Input
                  checked={template == "blank"}
                  onChange={() => setTemplate("blank")}
                  type="radio"
                  className="btn-check"
                  name={"answer-blanco"}
                />
                <Form.Check.Label className="btn btn-outline-light-primary text-dark-primary text-center d-flex flex-column align-items-center">
                  <Icon path={mdiFilePlus} size={3} />
                  Iniciar en blanco
                </Form.Check.Label>
              </Form.Check>
            </div>
          </Fragment>
        ) : (
          <div> </div>
        )}
      </div>

      <div className="mt-3">
        <div className={`d-flex justify-content-${currentPage > 1 ? "between" : "end"}`}>
          {currentPage > 1 && (
            <Button variant="secondary" className="text-white" onClick={prevPage}>
              <i className="fe fe-arrow-left"></i> Atrás
            </Button>
          )}
          {currentPage == nPages ? (
            <Button
              variant="primary"
              onClick={(e) => {
                e.preventDefault();
                terminar();
              }}
            >
              Finish
            </Button>
          ) : (
            <Button variant="primary" onClick={nextPage} disabled={disabled}>
              {" "}
              Siguiente <i className="fe fe-arrow-right"></i>
            </Button>
          )}
        </div>
      </div>

      {/* Quiz Pagination */}
    </Fragment>
  );
};

export default RegistrationProcess;
