// import node module libraries
import { mdiSquareRounded } from "@mdi/js";
import Icon from "@mdi/react";
import { DashboardContext } from "context/Context";
import { collection, getDocs, getFirestore, query, where } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { functions } from "index";

import ApexCharts from "components/elements/charts/ApexCharts";
import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Dropdown, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";

// import custom components
import StatRightIcon from "components/dashboard/common/stats/StatRightIcon";
// import { FlatPickr } from "components/elements/flat-pickr/FlatPickr";

// import sub components
// import PopularInstructor from "./PopularInstructor";
// import RecentCourses from "./RecentCourses";

// import data files
// import Calendar from "components/calendar/Calendar";
// import { TrafficChartOptions, TrafficChartSeries } from "data/charts/ChartData";

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <Link
    to=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    className="btn-icon btn btn-ghost btn-sm rounded-circle"
  >
    {children}
  </Link>
));

const ChartActionMenu = () => {
  return (
    <div>
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle}>
          <i className="fe fe-more-vertical text-muted"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu align="end">
          <Dropdown.Header>SETTINGS</Dropdown.Header>
          <Dropdown.Item eventKey="1">
            <i className="fe fe-external-link dropdown-item-icon "></i> Export
          </Dropdown.Item>
          <Dropdown.Item eventKey="2">
            <i className="fe fe-mail dropdown-item-icon "></i> Email Report
          </Dropdown.Item>
          <Dropdown.Item eventKey="3">
            <i className="fe fe-download dropdown-item-icon "></i> Download
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
const theme = {
  primary: "var(--geeks-primary)",
  secondary: "var(--geeks-secondary)",
  success: "var(--geeks-success)",
  info: "var(--geeks-info)",
  warning: "var(--geeks-warning)",
  danger: "var(--geeks-danger)",
  dark: "var(--geeks-dark)",
  light: "var(--geeks-light)",
  white: "var(--geeks-white)",
  gray100: "var(--geeks-gray-100)",
  gray200: "var(--geeks-gray-200)",
  gray300: "var(--geeks-gray-300)",
  gray400: "var(--geeks-gray-400)",
  gray500: "var(--geeks-gray-500)",
  gray600: "var(--geeks-gray-600)",
  gray700: "var(--geeks-gray-700)",
  gray800: "var(--geeks-gray-800)",
  gray900: "var(--geeks-gray-900)",
  black: "var(--geeks-black)",
  lightPrimary: "var(--geeks-light-primary)",
  lightSecondary: "var(--geeks-light-secondary)",
  lightSuccess: "var(--geeks-light-success)",
  lightDanger: "var(--geeks-light-danger)",
  lightWarning: "var(--geeks-light-warning)",
  lightInfo: "var(--geeks-light-info)",
  darkPrimary: "var(--geeks-dark-primary)",
  darkSecondary: "var(--geeks-dark-secondary)",
  darkSuccess: "var(--geeks-dark-success)",
  darkDanger: "var(--geeks-dark-danger)",
  darkWarning: "var(--geeks-dark-warning)",
  darkInfo: "var(--geeks-dark-info)",
  transparent: "transparent",
  borderColor: "var(--geeks-border-color)",
};

const days = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];

const DiaSemanaChartOptions = (gas) => {
  return {
    chart: { type: "bar", height: 302, sparkline: { enabled: !0 } },
    states: {
      normal: { filter: { type: "none", value: 0 } },
      hover: { filter: { type: "darken", value: 0.55 } },
      active: {
        allowMultipleDataPointsSelection: !1,
        filter: { type: "darken", value: 0.55 },
      },
    },
    colors: gas == "magna" ? [theme.success] : gas == "premium" ? [theme.danger] : [theme.primary],
    plotOptions: { bar: { borderRadius: 4, columnWidth: "50%" } },
    xaxis: { crosshairs: { width: 1 } },
    tooltip: {
      fixed: { enabled: !1 },
      x: { show: !1 },
      y: {
        title: {
          formatter: function (seriesName, { dataPointIndex }) {
            return `${days[dataPointIndex]}`;
          },
        },
      },
      marker: { show: !1 },
    },
    responsive: [
      { breakpoint: 480, options: { chart: { height: 300 } } },
      { breakpoint: 1441, options: { chart: { height: 300 } } },
      { breakpoint: 1981, options: { chart: { height: 300 } } },
      { breakpoint: 2500, options: { chart: { height: 400 } } },
      { breakpoint: 3000, options: { chart: { height: 450 } } },
    ],
  };
};

export const DonutOptions = {
  labels: ["Magna", "Premium"],
  colors: [theme.success, theme.danger],
  chart: { type: "donut" },
  legend: { show: !1 },
  dataLabels: { enabled: !1 },
  plotOptions: { pie: { donut: { size: "50%" } } },
  stroke: { width: 0, colors: [theme.borderColor] },
  responsive: [
    { breakpoint: 480, options: { chart: { height: 300 } } },
    { breakpoint: 1441, options: { chart: { height: 200 } } },
    { breakpoint: 1980, options: { chart: { height: 300 } } },
    { breakpoint: 2000, options: { chart: { height: 270 } } },
    { breakpoint: 2500, options: { chart: { height: 350 } } },
    { breakpoint: 3000, options: { chart: { height: 500 } } },
  ],
};
const Overview = () => {
  const db = getFirestore();
  const getPrices = httpsCallable(functions, "fetchPrices");

  const { user, company, setCompany } = useContext(DashboardContext);

  const [businessLocations, setBusinessLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState({
    value: { name: "Todas" },
    name: "Todas",
    label: "Todas",
  });

  const [loading, setLoading] = useState(true);
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [data, setData] = useState([]);
  const [change, setChange] = useState(false);
  const [totalMagnaLiters, setTotalMagnaLiters] = useState(0);
  const [totalPremiumLiters, setTotalPremiumLiters] = useState(0);
  const [totalMagnaSales, setTotalMagnaSales] = useState(0);
  const [totalPremiumSales, setTotalPremiumSales] = useState(0);
  const [totalMagnaProfit, setTotalMagnaProfit] = useState(0);
  const [totalPremiumProfit, setTotalPremiumProfit] = useState(0);
  const [daySalesMagna, setDaySalesMagna] = useState([]);

  const [daySalesPremium, setDaySalesPremium] = useState([]);

  const [daySales, setDaySales] = useState([]);

  let sDate = new Date(new Date().getFullYear(), currentMonth, 1);
  sDate.setHours(0, 1, 0, 0);

  let eDate = new Date(new Date().getFullYear(), currentMonth + 1, 0);
  eDate.setHours(23, 59, 59, 999);

  const [startDate, setStartDate] = useState(sDate);
  const [endDate, setEndDate] = useState(eDate);

  const initial = async (locss) => {
    // let startDate = new Date(new Date().getFullYear(), currentMonth, 0); // October 1st
    // let endDate = new Date(new Date().getFullYear(), currentMonth + 1, 0); // October 31st

    let totalMagnaLitersSold = 0;
    let totalPremiumLitersSold = 0;
    let totalMagnaSalesIncome = 0;
    let totalPremiumSalesIncome = 0;
    let magnaProfit = 0;
    let premiumProfit = 0;

    let weekDaySalesMagna = {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
    };

    let weekDaySalesPremium = {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
    };

    let weekDayCount = {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
    };

    console.log("locs, ", locss);
    var resp = [];
    const filesRef = collection(db, "companies", user.companyId, "dayInformation");
    const q = query(filesRef, where("date", ">=", startDate), where("date", "<=", endDate));
    const filesSnap = await getDocs(q);
    filesSnap.forEach((e) => {
      resp.push(e.data());
      console.log(new Date(e.data().date.seconds * 1000).toLocaleDateString("es-MX"));
      let weekDay = new Date(e.data().date.seconds * 1000).getDay();

      if (selectedLocation.value.name === "Todas") {
        locss.forEach((loc) => {
          let locData = e.data()[loc.locId];
          console.log("locData: ", locData);
          if (locData != undefined) {
            totalMagnaLitersSold += locData.magnaLitersSold;
            totalPremiumLitersSold += locData.premiumLitersSold;
            totalMagnaSalesIncome += locData.magnaSalesIncome;
            totalPremiumSalesIncome += locData.premiumSalesIncome;
            magnaProfit += locData.magnaSalesIncome - locData.magnaLitersSold * e.data().magnaCost;
            premiumProfit +=
              locData.premiumSalesIncome - locData.premiumLitersSold * e.data().premiumCost;
            console.log("weekDaySalesMagna: ", weekDay, " ", weekDaySalesMagna[weekDay]);
            console.log("magna plus: ", locData.magnaLitersSold);
            weekDaySalesMagna[weekDay] = weekDaySalesMagna[weekDay] + locData.magnaLitersSold;
            console.log("weekDaySalesMagna: ", weekDay, " ", weekDaySalesMagna[weekDay]);
            weekDaySalesPremium[weekDay] = weekDaySalesPremium[weekDay] + locData.premiumLitersSold;
          }
        });
      } else {
        let locData = e.data()[selectedLocation.value.locId];
        if (locData != undefined) {
          totalMagnaLitersSold += locData.magnaLitersSold;
          totalPremiumLitersSold += locData.premiumLitersSold;
          totalMagnaSalesIncome += locData.magnaSalesIncome;
          totalPremiumSalesIncome += locData.premiumSalesIncome;
          magnaProfit += locData.magnaSalesIncome - locData.magnaLitersSold * e.data().magnaCost;
          premiumProfit +=
            locData.premiumSalesIncome - locData.premiumLitersSold * e.data().premiumCost;

          weekDaySalesMagna[weekDay] += locData.magnaLitersSold;
          weekDaySalesPremium[weekDay] += locData.premiumLitersSold;
        }
      }

      weekDayCount[weekDay] += 1;
    });

    let weekDaySalesMagnaArray = [];
    let weekDaySalesPremiumArray = [];
    let totalWeekDaySales = [];

    for (let i = 0; i < 7; i++) {
      weekDaySalesMagnaArray.push(weekDaySalesMagna[i]);
      weekDaySalesPremiumArray.push(weekDaySalesPremium[i]);
      totalWeekDaySales.push(weekDaySalesMagna[i] + weekDaySalesPremium[i]);
    }

    console.log("weekDaySalesMagnaArray: ", weekDaySalesMagnaArray);
    console.log("weekDaySalesPremiumArray: ", weekDaySalesPremiumArray);
    console.log("totalWeekDaySales: ", totalWeekDaySales);

    console.log("weekDayCount: ", weekDayCount);
    //average the weekly results
    totalWeekDaySales.forEach((e, i) => {
      totalWeekDaySales[i] = totalWeekDaySales[i] / weekDayCount[i];
      weekDaySalesMagnaArray[i] = weekDaySalesMagnaArray[i] / weekDayCount[i];
      weekDaySalesPremiumArray[i] = weekDaySalesPremiumArray[i] / weekDayCount[i];
    });

    setDaySalesMagna(weekDaySalesMagnaArray);
    setDaySalesPremium(weekDaySalesPremiumArray);
    setDaySales(totalWeekDaySales);

    setTotalMagnaLiters(totalMagnaLitersSold);
    setTotalPremiumLiters(totalPremiumLitersSold);
    setTotalMagnaSales(totalMagnaSalesIncome);
    setTotalPremiumSales(totalPremiumSalesIncome);
    setTotalMagnaProfit(magnaProfit);
    setTotalPremiumProfit(premiumProfit);

    console.log("resp: ", resp);
    setData(resp);
  };

  const getLocs = async () => {
    var resp = [{ value: { name: "Todas" }, name: "Todas" }];
    const locsRef = collection(db, "companies", user.companyId, "locations");
    const loccSnap = await getDocs(locsRef);
    loccSnap.forEach((e) => {
      resp.push(e.data());
    });
    console.log(resp);
    if (resp.length >= 1) {
      setBusinessLocations(resp);
      // setSelectedLocation({ value: resp[0], label: resp[0].name });
    }
    return resp;
  };

  const calcAvg = (arr) => {
    let sum = 0;
    arr.forEach((e) => {
      sum += e;
    });
    return sum / arr.length;
  };

  useEffect(() => {
    setLoading(true);
    getLocs()
      .then((locs) => {
        initial(locs);
      })
      .then(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (data != []) {
      initial(businessLocations);
    }
  }, [change]);

  return (
    <div>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <div className="border-bottom pb-4 mb-4 d-lg-flex justify-content-between align-items-center">
            <div className="mb-3 mb-lg-0">
              <h1 className="mb-0 h2 fw-bold">Tablero</h1>

              {/* <Button
                onClick={(e) => {
                  e.preventDefault();
                  getPrices({ companyID: user.companyId }).then((result) => {
                    console.log(result.data);
                  });
                }}
              >
                Get prices
              </Button> */}
              <div>
                <h5 className="mb-0 card-title me-4">Selecciona la ubicación</h5>

                <Select
                  className="react-select-container"
                  classNamePrefix="react-select"
                  isClearable={false}
                  defaultValue={selectedLocation}
                  placeholder="Selecciona"
                  value={selectedLocation}
                  styles={{
                    control: (base) => ({
                      ...base,
                      height: "40px",
                      minHeight: "40px",
                      minWidth: "15rem",
                      borderRadius: "10px",
                      borderColor: "#ced4da",
                    }),
                  }}
                  options={businessLocations.map((e) => {
                    return { value: e, label: e.name };
                  })}
                  onChange={(e) => {
                    console.log(e);
                    setSelectedLocation(e);
                    setChange(!change);
                  }}
                />
              </div>
            </div>

            <div className="d-flex flex-row">
              <div className="d-flex flex-row me-2">
                <Button
                  variant="outline-primary"
                  className="me-2"
                  onClick={(e) => {
                    e.preventDefault();
                    setChange(!change);
                  }}
                >
                  <i className="fe fe-refresh-cw"></i>
                </Button>

                <div className="mx-1">
                  <h5>Fecha inicial</h5>
                  <input
                    type="date"
                    value={new Date(startDate).toISOString().split("T")[0]}
                    className="form-control"
                    max={new Date(endDate).toISOString().split("T")[0]}
                    onChange={(e) => {
                      console.log(new Date(e.target.value));
                      let d = new Date(e.target.value);
                      d.setHours(0, 1, 0, 0);
                      setStartDate(d);
                    }}
                  />
                </div>
                <div className="mx-1">
                  <h5>Fecha final</h5>
                  <input
                    type="date"
                    value={new Date(endDate).toISOString().split("T")[0]}
                    className="form-control"
                    min={new Date(startDate).toISOString().split("T")[0]}
                    onChange={(e) => {
                      console.log(new Date(e.target.value));
                      let d = new Date(e.target.value);
                      d.setHours(23, 59, 59, 999);
                      setEndDate(d);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <div>
          <Row>
            <Col xl={3} lg={6} md={12} sm={6}>
              <StatRightIcon
                title="Ingreso Bruto"
                value={"$ " + (totalMagnaSales + totalPremiumSales).toLocaleString("es-MX")}
                summary=" "
                summaryValue=" "
                summaryIcon="up"
                // showSummaryIcon
                iconName="shopping-bag"
                iconColorVariant="primary"
                classValue="mb-4"
              />
            </Col>

            <Col xl={3} lg={6} md={12} sm={6}>
              <StatRightIcon
                title="Litros"
                value={(totalMagnaLiters + totalPremiumLiters).toLocaleString("es-MX")}
                summary=" "
                summaryValue=" "
                summaryIcon="up"
                iconName="droplet"
                iconColorVariant="primary"
                classValue="mb-4"
              />
            </Col>

            <Col xl={3} lg={6} md={12} sm={6}>
              <StatRightIcon
                title="Utilidad"
                value={"$ " + (totalMagnaProfit + totalPremiumProfit).toLocaleString("es-MX")}
                // summary="Students"
                // summaryValue="+1200"
                summaryIcon="up"
                // showSummaryIcon
                iconName="dollar-sign"
                iconColorVariant="primary"
                classValue="mb-4"
              />
            </Col>

            <Col xl={3} lg={6} md={12} sm={6}>
              <StatRightIcon
                title="Utilidad %"
                value={
                  (
                    ((totalMagnaProfit + totalPremiumProfit) /
                      (totalMagnaSales + totalPremiumSales)) *
                    100
                  ).toFixed(2) + "%"
                }
                // summary="Instructor"
                // summaryValue="+200"
                // summaryIcon="up"
                // showSummaryIcon
                iconName="percent"
                iconColorVariant="primary"
                classValue="mb-4"
              />
            </Col>
          </Row>

          <Row>
            <Col xl={4} lg={4} md={12} className="mb-4">
              {/* <Card>
             <Card.Header className="align-items-center card-header-height d-flex justify-content-between align-items-center">
              <div>
                <h4 className="mb-0">Earnings</h4>
              </div>
              <div>
                <ChartActionMenu />
              </div>
            </Card.Header> 
            <Card.Body>
               <ApexCharts options={EarningsChartOptions} series={EarningsChartSeries} type="line" /> 
              <Calendar /> 
            </Card.Body>
          </Card> */}

              <Card className="h-100">
                <Card.Header className="align-items-center card-header-height d-flex justify-content-between align-items-center">
                  <h4 className="mb-0">Litraje</h4>
                </Card.Header>
                <Card.Body className="p-1">
                  <ApexCharts
                    options={DonutOptions}
                    series={[totalMagnaLiters, totalPremiumLiters]}
                    type="donut"
                    height={260}
                  />
                  <Table size="sm" className="mt-5 mb-0 ms-2" borderless>
                    <tbody>
                      <tr>
                        <td>
                          <Icon
                            path={mdiSquareRounded}
                            className="text-success fs-5 me-2"
                            size={0.6}
                          />
                          Magna
                        </td>
                        <td>{totalMagnaLiters.toLocaleString()}</td>
                        <td>
                          {(
                            (totalMagnaLiters / (totalMagnaLiters + totalPremiumLiters)) *
                            100
                          ).toFixed(2)}{" "}
                          %
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Icon
                            path={mdiSquareRounded}
                            className="text-danger fs-5 me-2"
                            size={0.6}
                          />
                          Premium
                        </td>
                        <td>{totalPremiumLiters.toLocaleString()}</td>
                        <td>
                          {(
                            (totalPremiumLiters / (totalMagnaLiters + totalPremiumLiters)) *
                            100
                          ).toFixed(2)}{" "}
                          %
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>

            <Col xl={4} lg={4} md={12} className="mb-4">
              <Card className="h-100">
                <Card.Header className="align-items-center card-header-height d-flex justify-content-between align-items-center">
                  <h4 className="mb-0">Utilidad</h4>
                </Card.Header>
                <Card.Body className="p-1">
                  <ApexCharts
                    options={DonutOptions}
                    series={[totalMagnaProfit, totalPremiumProfit]}
                    type="donut"
                    height={260}
                  />
                  <Table size="sm" className="mt-5 mb-0 ms-2" borderless>
                    <tbody>
                      <tr>
                        <td>
                          <Icon
                            path={mdiSquareRounded}
                            className="text-success fs-5 me-2"
                            size={0.6}
                          />
                          Magna
                        </td>
                        <td>{"$ " + totalMagnaProfit.toLocaleString("es-MX")}</td>

                        <td>
                          {(
                            (totalMagnaProfit / (totalMagnaProfit + totalPremiumProfit)) *
                            100
                          ).toFixed(2)}{" "}
                          %
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Icon
                            path={mdiSquareRounded}
                            className="text-danger fs-5 me-2"
                            size={0.6}
                          />
                          Premium
                        </td>
                        <td>{"$ " + totalPremiumProfit.toLocaleString("es-MX")}</td>
                        <td>
                          {(
                            (totalPremiumProfit / (totalMagnaProfit + totalPremiumProfit)) *
                            100
                          ).toFixed(2)}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
            <Col xl={4} lg={4} md={12} className="mb-4">
              <Card className="h-100">
                <Card.Header className="align-items-center card-header-height d-flex justify-content-between align-items-center">
                  <h4 className="mb-0">Litraje promedio x día Combinado</h4>
                  <p>
                    Promedio:{" "}
                    {(calcAvg(daySalesMagna) + calcAvg(daySalesPremium)).toLocaleString("es-MX")}{" "}
                    Lts
                  </p>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col>
                      <span className="fw-semi-bold">Día más alto</span>
                      <h1 className="fw-bold mt-2 mb-0 h2">
                        {days[daySales.indexOf(Math.max(...daySales))]}
                      </h1>
                      {/* <p className="text-success fw-semi-bold mb-0">
                        <i className="fe fe-trending-up me-1"></i>4.6%
                      </p> */}
                    </Col>
                    <Col>
                      <span className="fw-semi-bold">Día más bajo</span>
                      <h1 className="fw-bold mt-2 mb-0 h2">
                        {days[daySales.indexOf(Math.min(...daySales))]}
                      </h1>
                      {/* <p className="text-danger fw-semi-bold mb-0">
                        <i className="fe fe-trending-down me-1"></i>4.6%
                      </p> */}
                    </Col>
                  </Row>
                  <ApexCharts
                    options={DiaSemanaChartOptions("combinado")}
                    series={[{ data: daySales }]}
                    type="bar"
                  />
                </Card.Body>
              </Card>
            </Col>

            <Col xl={6} lg={6} md={12} className="mb-4">
              <Card className="h-100">
                <Card.Header className="align-items-center card-header-height d-flex justify-content-between align-items-center">
                  <h4 className="mb-0 text-success">Litraje promedio x día Magna</h4>
                  <p>Promedio: {calcAvg(daySalesMagna).toLocaleString("es-MX")} Lts</p>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col>
                      <span className="fw-semi-bold">Día más alto</span>
                      <h1 className="fw-bold mt-2 mb-0 h2">
                        {days[daySalesMagna.indexOf(Math.max(...daySalesMagna))]}
                      </h1>
                      {/* <p className="text-success fw-semi-bold mb-0">
                        <i className="fe fe-trending-up me-1"></i>4.6%
                      </p> */}
                    </Col>
                    <Col>
                      <span className="fw-semi-bold">Día más bajo</span>
                      <h1 className="fw-bold mt-2 mb-0 h2">
                        {days[daySalesMagna.indexOf(Math.min(...daySalesMagna))]}
                      </h1>
                      {/* <p className="text-danger fw-semi-bold mb-0">
                        <i className="fe fe-trending-down me-1"></i>4.6%
                      </p> */}
                    </Col>
                  </Row>
                  <ApexCharts
                    options={DiaSemanaChartOptions("magna")}
                    series={[{ data: daySalesMagna }]}
                    type="bar"
                  />
                  <br />
                  <small>Lista de factores para día de la semana:</small>
                  <ol>
                    {daySalesMagna.map((e, i) => {
                      let factor = e / calcAvg(daySalesMagna);
                      return (
                        <li>
                          {days[i]}: {factor}
                        </li>
                      );
                    })}
                  </ol>
                </Card.Body>
              </Card>
            </Col>

            <Col xl={6} lg={6} md={12} className="mb-4">
              <Card className="h-100">
                <Card.Header className="align-items-center card-header-height d-flex justify-content-between align-items-center">
                  <h4 className="mb-0 text-danger">Litraje promedio x día Premium</h4>
                  <p>Promedio: {calcAvg(daySalesPremium).toLocaleString("es-MX")} Lts</p>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col>
                      <span className="fw-semi-bold">Día más alto</span>
                      <h1 className="fw-bold mt-2 mb-0 h2">
                        {days[daySalesPremium.indexOf(Math.max(...daySalesPremium))]}
                      </h1>
                      {/* <p className="text-success fw-semi-bold mb-0">
                        <i className="fe fe-trending-up me-1"></i>4.6%
                      </p> */}
                    </Col>
                    <Col>
                      <span className="fw-semi-bold">Día más bajo</span>
                      <h1 className="fw-bold mt-2 mb-0 h2">
                        {days[daySalesPremium.indexOf(Math.min(...daySalesPremium))]}
                      </h1>
                      {/* <p className="text-danger fw-semi-bold mb-0">
                        <i className="fe fe-trending-down me-1"></i>4.6%
                      </p> */}
                    </Col>
                  </Row>
                  <ApexCharts
                    options={DiaSemanaChartOptions("premium")}
                    series={[{ data: daySalesPremium }]}
                    type="bar"
                  />
                  <br />
                  <small>Lista de factores para día de la semana:</small>
                  <ol>
                    {daySalesPremium.map((e, i) => {
                      let factor = e / calcAvg(daySalesPremium);
                      return (
                        <li>
                          {days[i]}: {factor}
                        </li>
                      );
                    })}
                  </ol>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      )}

      {/* <Row>
        <Col xl={4} lg={6} md={12} className="mb-4">
          <PopularInstructor title="Popular Instructor" />
        </Col>
        <Col xl={4} lg={6} md={12} className="mb-4">
          <RecentCourses title="Recent Courses" />
        </Col>

        <Col xl={4} lg={6} md={12} className="mb-4">
          <Activity title="Activity" />
        </Col>
      </Row> */}
    </div>
  );
};

export default Overview;
